import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {HashRouter, Route, Redirect, Switch} from 'react-router-dom';
import './scss/style.scss';
import '@coreui/icons/css/all.css';
import 'codemirror/lib/codemirror.css'
// import 'codemirror/theme/material.css'
import 'codemirror/theme/eclipse.css'
// import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
// import 'codemirror/mode/xml/xml'
import ForgotPassword from "./views/pages/ForgotPassword";
import ResetPassword from "./views/pages/ResetPassword";

import {isAuthenticated} from './actions/auth';
import {getEntities as getOrganisations} from './reducers/organisationReducer';
import {setCurrentOrganisation} from './reducers/contextReducer';

// Pages
import Login from "./views/pages/Login";

// Containers
import TheLayout from "./containers/TheLayout";
import history from "./history";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const Loader = () => (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const PublicRoute = ({component: Component, ...rest}) => {
    const loggedIn = useSelector(state => state.auth.authenticated);

    if (loggedIn === null) {
        return <Loader/>
    }

    console.log('PublicRoute: ', rest);

    return (
        <Route {...rest} render={(props) => (
            !loggedIn
                ? <Component {...props} />
                : <Redirect to='/dashboard'/>
        )}/>
    )
};


const PrivateRoute = ({component: Component, ...rest}) => {
    const loggedIn = useSelector(state => state.auth.authenticated);

    if (loggedIn === null) {
        return <Loader/>
    }

    console.log('PrivateRoute: ', loggedIn);

    return (
        <Route {...rest} render={(props) => (
            loggedIn ? <Component {...props} />
                : <Redirect to='/login'/>
        )}/>
    )
};


const App = () => {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.auth.authenticated);
    const organisations = useSelector(state => state.organisation.entities);
    const currentOrganisation = useSelector(state => state.context.currentOrganisation);

    // load user
    useEffect(() => {
        dispatch(isAuthenticated());
    }, [loggedIn]);

    // load organisations once user is logged in
    useEffect(() => {
        if (loggedIn) {
            dispatch(getOrganisations());
        }
    }, [loggedIn]);

    // when organisations have loaded then set context for org and env
    useEffect(() => {
        if (!currentOrganisation) {
            if (organisations && organisations.length > 0) {
                dispatch(setCurrentOrganisation(organisations[0]))
            }
        }
    }, [organisations, currentOrganisation]);

    console.log(loggedIn, currentOrganisation);

    return (
        <>
            {loggedIn ? (
                <>
                    {currentOrganisation && (
                        <Switch>
                            <PublicRoute path="/login" exact={true} component={Login}/>
                            <PublicRoute path="/forgotpassword" exact={true} component={ForgotPassword}/>
                            <PublicRoute path="/resetpassword" exact={true} component={ResetPassword}/>
                            <PrivateRoute path="/" component={TheLayout}/>
                        </Switch>
                    )}
                </>
            ) : (
                <Switch>
                    <PublicRoute path="/resetpassword" exact={true} component={ResetPassword}/>
                    <PublicRoute path="/login" exact={true} component={Login}/>
                    <PublicRoute path="/forgotpassword" exact={true} component={ForgotPassword}/>
                    {loggedIn !== null && <Redirect to='/login'/>}
                </Switch>
            )}
        </>
    );
};

export default App;
