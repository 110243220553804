import React from 'react';
import {ButtonToolbar, CardBody, InputGroup} from "reactstrap";
import {CButton, CButtonGroup, CCard, CCardBody, CCardHeader, CTooltip, CWidgetProgress} from "@coreui/react";
import {Badge} from "react-bootstrap";
import EventButtons from "./EventButtons";

import Player from "./Player";
const EventDetailStreaming = ({
                                  event,
                                  eventAttendeeStats,
                                  handleDisableRmtps,
                                  handleEnableRmtps,
                                  handleVideoUpdate,
                                  rmtps
                              }) => {


    return <>
        <h3 className="py-2">Stream Control</h3>
        <h4 className="py-2">RTMP Settings</h4>
        <p>Stream to this url:</p>
        <ButtonToolbar>
            <CButtonGroup className="mr-2">
                <CButton onClick={handleEnableRmtps} color={ rmtps ? 'info' : 'secondary'}>RMTPS</CButton>
                <CButton onClick={handleDisableRmtps} color={ rmtps ? 'secondary' : 'info'}>RMTP</CButton>
            </CButtonGroup>
            <InputGroup>
                <div className="form-control">
                    <code className="highlight-rouge">{ rmtps ? `rtmps://global-live.mux.com:443/app/${event.streamKey}` : `rtmp://global-live.mux.com:5222/app/${event.streamKey}`}</code>
                </div>
            </InputGroup>
        </ButtonToolbar>

        {/*<CButton color="success" className="mt-3">Copy stream key</CButton>*/}



        <hr className="my-4" />

        <h4 className="pb-2">Streaming Status</h4>

        <div className="row">
            <div className="col-8">

                <CCard>
                    <CCardHeader>
                        <CTooltip content="Event is upcoming">
                            <Badge variant={ event.streamStatus === 'pending' ? 'info' : 'light'} className="mr-2">PENDING</Badge>
                        </CTooltip>

                        <CTooltip content="Users can go into 'watch' page and see countdown timer">
                            <Badge variant={ event.streamStatus === 'open' ? 'success' : 'light'} className="mr-2">OPEN</Badge>
                        </CTooltip>

                        <CTooltip content="Users can see live stream">
                            <Badge variant={ event.streamStatus === 'live' ? 'danger' : 'light'} className="mr-2">ON AIR</Badge>
                        </CTooltip>

                        <CTooltip content="Interrupted live stream - users see an 'interrupted' message">
                            <Badge variant={ event.streamStatus === 'live_interrupted' ? 'warning' : 'light'} className="mr-2">OFF AIR</Badge>
                        </CTooltip>

                        <CTooltip content="Event has finished, live stream stopped">
                            <Badge variant={ event.streamStatus === 'live_finished' ? 'success' : 'light'} className="mr-2">FINISHED</Badge>
                        </CTooltip>

                        <CTooltip content="Users can see event on video on demand, if available">
                            <Badge variant={ event.streamStatus === 'vod' ? 'info' : 'light'} className="mr-2">VIDEO ON DEMAND</Badge>
                        </CTooltip>

                        <CTooltip content="Event can no longer be watched in any mode">
                            <Badge variant={ event.streamStatus === 'closed' ? 'dark' : 'light'}>CLOSED</Badge>
                        </CTooltip>
                    </CCardHeader>
                    <CCardBody>
                        <EventButtons event={event} handleVideoUpdate={handleVideoUpdate} />
                    </CCardBody>
                </CCard>

            </div>
            <div className="col-2">
                <CWidgetProgress
                    color="primary"
                    header={eventAttendeeStats?.currentUsers}
                    text="Online Viewers"
                />
            </div>
            <div className="col-2">
                <CWidgetProgress
                    color="success"
                    header={eventAttendeeStats?.totalViewers}
                    text="Total Viewers"
                />
            </div>
        </div>



        <hr className="my-4" />

        <h4 className="pb-2">Streaming Previews</h4>

        <div className="row">

            <div className="col">
                <h5 className="my-2">Live &amp; linear</h5>
                <Player
                    streamPlaybackId={event.streamPlaybackId}
                    dataStatus={event.dataStatus} />
            </div>

            <div className="col">
                {event.streamEnableVod && (
                    <>
                        <h5 className="my-2">Video on demand</h5>

                        <Player
                            src={"https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8"}/>
                    </>
                )}
            </div>


        </div>



        {/*

                                            <hr className="my-4" />


                                                    <h3>Embed code</h3>

                                                    <p>Please put this inside the <code className="highlight-rouge">{decode('<head></head>')}</code> tags in your page:</p>

                                                    <div className="form-control">
                                                        <code className="highlight-rouge">{decode('<script type="text/javascript" src="https://scripts.440.io/v1/orchestra.min.js"></script>')}</code>
                                                    </div>

                                                    <CButton color="success" className="mt-3">Copy script code</CButton>

                                                    <p className="mt-3">Please put this inside the <code className="highlight-rouge">{decode('<body></body>')}</code> tags where you want the player to appear:</p>

                                                    <div className="form-control" style={{height: "100px"}}>
                                                        <code className="highlight-rouge">
                                                            { decode(`<div id="event-${event.id}"></div>`)}
                                                            <br />
                                                            { decode(`<script type="text/javascript">var conductor = new _440Conductor('41cad05c-6a93-4cd2-aa07-5db7ac3c86a0', '${event.id}');</script>`)}
                                                        </code>


                                                    </div>

                                                    <CButton color="success" className="mt-3">Copy embed code</CButton>

                                        */}
    </>

}

export default EventDetailStreaming;