import React from 'react'
import AudienceAdmin from '../../components/users/AudienceAdmin';
import { Link } from 'react-router-dom';
import {contextAwareUrl} from '../../helpers'

const AccountAdminPage = (props) => {
    return (
        <>
            <Link to={contextAwareUrl('/accounts')} className="btn btn-dark w-fit mb-3 d-flex align-items-center">
                <span className="cil-arrow-left btn-icon mr-2"></span> Back to accounts
            </Link>
            <AudienceAdmin id={props.match.params.id} />
        </>
    )
};

export default AccountAdminPage;
