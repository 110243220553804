import React, {useState} from 'react';
import {CButton, CButtonGroup, CFormGroup, CLabel} from "@coreui/react";
import Dropzone from "react-dropzone";
import {
    CCard,
    CCardBody,
    CSwitch
} from  '@coreui/react'
import {ButtonToolbar, Col, InputGroup, Row} from "reactstrap";
import {FormControl} from "react-bootstrap";
import formConfig from "../../config/formConfig.json";


const EventUpdateFormStreamSettings = ({showFiles,form}) => {

    const [isPrerecorded, setIsPrerecorded] = useState(false);
    const [fileUpload, setFileUpload] = useState(true);


    return <>

        <h3 className="py-2">Stream Settings</h3>


        <div className="pb-4">
            <p className="mb-2">How will you deliver this event?</p>
            <CButtonGroup>
                <CButton
                    onClick={()=>setIsPrerecorded(false)}
                    color={!isPrerecorded ? 'primary' : 'secondary'}>Live</CButton>
                <CButton
                    onClick={()=>setIsPrerecorded(true)}
                    color={isPrerecorded ? 'info' : 'secondary'}>Pre-recording</CButton>
            </CButtonGroup>
        </div>

        { isPrerecorded ? (
            <CCard className="bg-light shadow-none text-body" >
                <CCardBody>
                    <h4>File upload</h4>

                    <p className="font-sm help-block">Videos can either be streamed directly from a public URL (for example on Dropbox), or uploaded directly here (up to a maximum of 5gb).</p>

                    <div className="row no-gutters mb-4">
                        <div className="col-auto">
                            <CButtonGroup className="mr-3">
                                <CButtonGroup>
                                    <CButton
                                        onClick={()=>setFileUpload(true)}
                                        color={fileUpload ? 'info' : 'secondary'}>Upload file</CButton>
                                    <CButton
                                        onClick={()=>setFileUpload(false)}
                                        color={!fileUpload ? 'info' : 'secondary'}>Stream from URL</CButton>
                                </CButtonGroup>
                            </CButtonGroup>
                        </div>
                        <div className="col">

                                { fileUpload ? (<>
                                    <Dropzone id="event-video"
                                              onDrop={acceptedFiles => showFiles(acceptedFiles)}>
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <section>
                                                <div className="dropzone" {...getRootProps()}>
                                                    <input {...getInputProps()} multiple={false}
                                                           id="event-file"/>
                                                    {
                                                        isDragActive ?
                                                            <p>Drop the file here ...</p> :
                                                            <p>Drag a file here, or click to browse
                                                                files</p>
                                                    }

                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <ul className={'upload-results pt-3'}></ul>
                                </>) : (<input className="form-control" type="text" name={'streamFileUrl'} ref={form.register} />)}

                        </div>
                    </div>

                    <div className="row no-gutters">
                        <div className="col-auto">
                            <CSwitch
                                color="info"
                                name="streamAutoStart"
                                innerRef={form.register}
                                variant="3d"
                                labelOn={'\u2713'} labelOff={'\u2715'}
                            />
                        </div>
                        <div className="col">
                            <CLabel className="ml-3" htmlFor="streamAutoStart">Automatically start streaming at allotted time. (If turned off you will need to manually start)</CLabel>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        ) : (<></>)}

        <div className="row no-gutters">
            <div className="col-auto">
                <CSwitch
                    color="primary"
                    name="streamEnableVod"
                    innerRef={form.register}
                    variant="3d"
                    labelOn={'\u2713'} labelOff={'\u2715'}
                />
            </div>
            <div className="col">
                <CLabel className="ml-3" htmlFor="streamEnableVod">Enable availability of video-on-demand playback after event has finished streaming.</CLabel>
            </div>
        </div>

    </>


}

export default EventUpdateFormStreamSettings;